import dayjs from 'dayjs';
import { filter } from 'rxjs';
import { WebsocketsService } from '../core/services/websockets.service';
import { PartStatus } from '../parts/models/part.model';
import { Quote } from '../quotes/models/quote.model';
import { QuotesService } from '../quotes/services/quotes.service';

let quote: Quote;
const quote$ = QuotesService.prototype.quote;
const websockets = WebsocketsService.prototype;

quote$?.subscribe((res) => (quote = res));

export const predicates = {
  isAddedByBodyParts: (value: any) => value.added && !value.removed,
  isAddedByRepairer: (value: any) => value.addedByRepairer,
  isCurrentOrder: (orders: any, orderIDs: string[]) => orders.filter((order: any) => orderIDs.includes(order.link)),
  isDateLessThan30DaysAgo: (value: any) => timeDiff(value.createdAt) < convertToMilliseconds(30, 'd'),
  isDateMoreThan30DaysAgo: (value: any) => timeDiff(value.createdAt) > convertToMilliseconds(30, 'd'),
  isDateThisWeek: (value: any) =>
    timeDiff(value.createdAt) >= convertToMilliseconds(2, 'd') &&
    timeDiff(value.createdAt) < convertToMilliseconds(7, 'd'),

  isDateToday: (value: any) => dayjs().isSame(value.createdAt, 'day'),
  isDateYesterday: (value: any) =>
    !predicates.isDateToday(value) && timeDiff(value.createdAt) < convertToMilliseconds(2, 'd'),
  isDelivered: (value: any) => value.status === PartStatus.DELIVERED,
  isShipped: (value: any) => value.status === PartStatus.SHIPPED,
  isCancelled: (value: any) => value.status === PartStatus.CANCELLED,
  isFalse: (value: any) => value === false,
  isPartValid: (value: any) => value.rrp && value.net && value.unit && value.unit <= value.rrp,
  isRemovedBySupplier: (value: any) => value.removed && !value.removedByRepairer,
  isRemovedByRepairer: (value: any) => value.removed && value.removedByRepairer,
  isSameSocketEvent: (eventName: string) => filter((data: any) => data?.eventName.includes(eventName)),
  isTrue: (value: any) => value === true,
  matchesEnv: () => filter((data: any) => websockets.matchesEnv(data.eventName)),
  notAddedByRepairer: (value: any) => !predicates.isAddedByRepairer(value),
  notEmptyObject: (obj: any) => Object.keys(obj).length !== 0,
  notNullOrUndefined: (value: any) => (value ? notNullOrUndefinedProps(value) : notNullOrUndefinedValue(value)),
  notNull: (value: any) => value !== null,
  notPartBreakdownHeader: (value: any) => !(value.partCode && value.kitHeader) && !value.partNumber.startsWith('SEE'),
  notQuoteIsLocked: () => !quote.locked,
  notRemovedByRepairer: (value: any) => !predicates.isRemovedByRepairer(value),
  notRemovedFromParts: (value: any) => !value.removed,
  notCancelledFromParts: (value: any) => !predicates.isCancelled(value),
  notZeroLength: (value: any) => (Array.isArray(value) ? notZeroLengthList(value) : notZeroLengthProps(value)),
  orderedByItemIndexAsc: (first: any, second: any) => first.ordinal - second.ordinal
};

const convertToMilliseconds = (time: number, qualifier: string) => {
  switch (qualifier) {
    case 's':
      return time * 1000;

    case 'm':
      return convertToMilliseconds(time * 60, 's');

    case 'h':
      return convertToMilliseconds(time * 60, 'm');

    case 'd':
      return convertToMilliseconds(time * 24, 'h');

    case 'w':
      return convertToMilliseconds(time * 7, 'd');

    default:
      return time;
  }
};

const notNullOrUndefinedValue = (value: any) => !(value === null || value === undefined);

const notNullOrUndefinedProps = (obj: any) =>
  Object.keys(obj).some((prop) => {
    const value = obj[prop];
    return notNullOrUndefinedValue(value);
  });

const notZeroLengthList = (list: any[]) => list.length !== 0;

const notZeroLengthProps = (obj: any) =>
  Object.keys(obj).every((prop) => {
    const value = obj[prop];
    return value?.length && value.length !== 0;
  });

const timeDiff = (date: string) => Math.abs(dayjs().diff(dayjs(date)));
